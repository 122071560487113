$pink: rgba(194,34,75,255);

body {
  background-color: #fbeceb !important;
  /* The image used */
  background-image: url('../../resources/images/rsvp_background.png');

  /* Center and scale the image nicely */
  background-position: center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -o-background-size: cover;
  transition: height 2s; }

.form-section {
  box-shadow: black;
  background-color: #f3c9d5;
  border-radius: 10px; }

label {
  font-family: bright_2;
  color: $pink;
  -webkit-text-stroke: 0.3px black; }

label {
  font-size: x-large; }

.rs-radio-checker .rs-radio-wrapper .rs-radio-inner::before {
  border-color: $pink; }

.rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
  background-color: gold;
  border-color: gold; }

.submitButton {
  width: 50% !important;
  background-color: rgba(255,255,255, 0.5) !important; }

.error-block {
  padding: 10px 10px 0px 10px;
  background-color: rgba(255, 0, 0, 0.2);
  color: darkred;
  border-style: solid;
  border-width: 0.1px;
  border-radius: 10px;
  p {
    font-size: medium !important; } }
