@font-face {
    font-family: 'Goudy Heavyface';
    src: url('Goudy-Heavyface.woff2') format('woff2'),
        url('Goudy-Heavyface.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'retroking';
    src: url('retroking/Retroking.woff2') format('woff2'),
    url('retroking/Retroking.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'bright_2';
    src: url('bright_2/Bright DEMO.woff2') format('woff2'),
    url('bright_2/Bright DEMO.woff') format('woff');
    font-style: normal;
    font-display: swap;
}