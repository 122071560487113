@import 'rsuite/dist/styles/rsuite-default.css';
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');
@import "./style/fonts.css";
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url("https://fonts.googleapis.com/css?family=Merienda");

$mobile-size-limit: 480px;

$section-size: 700px;
$Section-padding: 40px;
$Section-image-height: 620px;
$oh-hover-multiplier: 1.2;

$section-size-mobile: 400px;
$Section-padding-mobile: 20px;
$Section-image-height-mobile: 360px;

$primary-colour: rgb(164, 74, 63);
$secondary-colour: #fbeceb;
$pink: #c2224b;
$light-pink: #f088a2;
p {
    font-family: bright_2, Lato;
    text-shadow: 1px 1px $light-pink;
    font-size: 25px;
    color: $pink;
    transition: height 2s;
    @media (max-width: 480px) {
        font-size: 18px; } }

h1, h2, h3 {
    font-family:  bright_2, Lato, 'Goudy Heavyface', "Comic Sans MS";
    -webkit-text-stroke: 0.6px black;
    color: $pink;
    letter-spacing: 0.1em;
    text-shadow: 2px 2px $light-pink; }

.background-transparent {
    background-color: transparent;
    font-weight: bolder; }

html, body, #root, #root>.header {
    height: 100%;
    scroll-behavior: smooth; }

.background-image {
    /* The image used */
    background-image: url('./resources/images/HeaderImage_white.png');

    @media (max-width: 480px) {
        height: $section-size-mobile;
        background-image: url('./resources/images/HeaderImage_mobile_white.png'); }

    height: $section-size;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover; }

.background-image-section {
    @media (max-width: 480px) {
        height: $section-size-mobile; }
    height: $section-size;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; }

.bordered {
    border-top: double;
    border-bottom: double; }

.section {
    z-index: 10;
    @media (max-width: 480px) {
        height: $section-size-mobile;
        padding: $Section-padding-mobile; }

    height: $section-size;
    padding: $Section-padding;
    transition: height 2s;
    transition: background-color 2s;
    &:hover {
        @media (max-width: 480px) {
            .section-image {
                height: $section-size-mobile; }
            background-color: rgba(255,255,255,0.6);
            height: $section-size-mobile; }

        background-color: rgba(255,255,255,0.6); } }


.section-welcome {
    @media (max-width: 480px) {
        height: 400px;
        padding: $Section-padding-mobile; }

    height: 350px;
    padding: $Section-padding; }

.centre {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: height 2s; }

.background-primary {
    background-color: $primary-colour; }

.background-secondary {
    background-color: $secondary-colour;
    background-image: linear-gradient(to bottom right, $secondary-colour, $secondary-colour, white, white, $secondary-colour); }

.section-image {
    @media (max-width: 480px) {
        height: $Section-image-height-mobile; }
    height: $Section-image-height;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: height 2s;
    border-radius: 20px; }

.window-image {
    border-radius: 200px 200px 0px 0px; }

.right-border {
    border-right-style: double; }

.left-border {
    border-left-style: double; }

.link-style {
    text-decoration: none;
    //color: $light-pink
    color: white;
    text-shadow: 2px 2px $pink;
    -webkit-text-stroke: 0.8px black;

    &:hover, &:visited, &:focus {
        text-decoration: none;
        opacity: 0.5; } }

.sticky-nav {
    position: fixed;
    z-index: 1;
    top: 0;
    min-width: 100%;
    background-color: rgba(255,255,255,0.4);
    @media (max-width: 480px ) {
        background-color: transparent; } }

.stick-nav-mobile {
    position: fixed; }

.dropdown-menu {
    background-color: rgba(255, 255, 255, 0.2) !important; }

.shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.iconStyle {
    &.white {
        color: white; }
    color: $pink;
    stroke: black;
    strokeWidth: 10; }

.wrapper {
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    max-width: 100%;

    h1 {
        text-align: center;
        position: absolute;
        left: 50%;
        top: calc(50% - 200px);
        -webkit-transform: translate(-50%, calc(-50% - 64px));
        -ms-transform: translate(-50%, calc(-50% - 64px));
        transform: translate(-50%, calc(-50% - 64px));
        font-size: 80px !important; } }

.wrapper h1 span {
    position: absolute; }

.basket {
    transition: opacity 0.5s;
    opacity: 1; }

.basket.hide {
    opacity: 0;
    pointer-events: none; }

.info-block {
    background-color: #f3c9d5;
    padding: 15px;
    border-radius: 25px;
    transition: height 2s;
    transition: all 2s;
    @media (min-width: 480px ) {
        h1 {
            font-size: xxx-large; }
        p {
            font-size: xx-large; } }
    @media (max-width: 480px) {
        p {
            padding: 5px; } }
    p {
        font-family: bright_2;
        color: #c24562; } }

.travel-info {
    height: $section-size * 3/12;
    @media (max-width: 480px) {
        height: $section-size-mobile; } }

.map-outer {
    height: $Section-image-height  * 9/12;
    width: 100%; }

.map {
    height: $Section-image-height * 8/12;
    padding: 0 $Section-padding / 2 $Section-padding / 2;
    width: 100%; }

.map-inner {
    height: 100%;
    width: 100%; }

.accommodation-card {
    font-size: larger;
    color: $pink;
    background-color: #f3c9d5;
    height: 100%; }

.accommodation-col {
    padding-top: 12px;
    padding-bottom: 12px;
    height: (($Section-image-height - 90) / 2) - $Section-padding/2; }

.card-link {
    text-shadow: none !important;
    padding: 0px 5px 0px 5px; }

.number {
    font-size: smaller; }

.card-text {
    font-family: Lato;
    margin: 0px;
    color: black;
    padding: 0px 5px 0px 5px;
    font-size: 20px; }


.card {
    background-color: rgba(243, 201, 213, 0.6) !important; }

.card-title {
    border-bottom: double;
    background-color: rgb(243, 201, 213);
    padding: 0px 5px 0px 5px;
    margin: 0 !important; }

.registry-block {
    @media (min-width: 480px) {
        width: 100%; }
    width: 60% !important;
    margin-right: 20%;
    margin-left: 20%; }

footer {
    height: 50px; }

.table {
    background-color: rgba(255, 255, 255, 0.7); }

.button {
    border-radius: 15px;
    background-color: $pink;
    transition: background-color 1s;
    &:hover {
        background-color: black; } }

.recent-row {
    background-color: rgba(102, 255, 0, 0.5); }

.medium-recent-row {
    background-color: rgba(255, 223, 0, 0.5); }
